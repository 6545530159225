import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFetchFlowBlocks from '../../api/FetchFlowBlocks';
import { setInitialFlowData } from '../../redux/student/studentSlice';
import Lottie from 'lottie-react';
import animationData from '../../assets/Animation/loading_animation.json';

const FlowBlocksChange = () => {
    const [queryParams, setQueryParams] = useState( {} );
    const [testID, setTestID] = useState( localStorage.getItem( 'testId' ) );
    const [testSeriesID, setTestSeriesID] = useState( localStorage.getItem( 'testSeriesId' ) );

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { test_id, test_series_id } = location.state || {};

    const { client_name, grade, user_type, test_type, testId } = useParams();

    useEffect( () => {
        if ( test_id ) {
            setTestID( test_id )
        } else if ( testId ) {
            setTestID( testId )
        } else {
            setTestID( localStorage.getItem( 'testId' ) )
        }

        if ( test_series_id ) {
            setTestSeriesID( test_series_id )
        } else {
            setTestSeriesID( localStorage.getItem( 'testSeriesId' ) )
        }
    }, [] )

    const searchParams = new URLSearchParams( location.search );
    const userEmail = searchParams.get( 'user_email' );
    const userName = searchParams.get( 'user_name' );
    const studentRollNumber = searchParams.get( 'user_id' );
    const currentFlowName = useSelector( ( state ) => state.flow.currentFlowName )
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_data;
    const template_id = formConfig?.pdf_template_name;

    const userEmailLocalStorage = localStorage.getItem( 'user_email' ) || userEmail || "";
    const userNameLocalStorage = localStorage.getItem( 'studentName' ) || userName || "";
    const userId = localStorage.getItem( 'user_id' ) || studentRollNumber || "";

    const { response: flowBlocks, FetchFlowBlocks, isLoading } = useFetchFlowBlocks();

    useEffect( () => {
        if ( testID && testID !== "null" ) {
            FetchFlowBlocks( testID );
        }
    }, [testID] )

    useEffect( () => {
        if ( flowBlocks && !currentFlowName ) {
            dispatch( setInitialFlowData( flowBlocks ) );
        }
    }, [flowBlocks] );

    useEffect( () => {
        const params = {};
        for ( let [key, value] of searchParams.entries() ) {
            params[key] = value;
        }

        if ( Object.keys( params ).length > 0 ) {
            localStorage.setItem( 'test_query_params', JSON.stringify( params ) );
            setQueryParams( params );
        }
    }, [location.search] );

    const constructUrlWithParams = ( baseUrl ) => {
        const sessionUtmParams = JSON.parse( sessionStorage.getItem( 'utm_parameters' ) ) || {};
        const queryParamsWithUtm = { ...queryParams, ...sessionUtmParams };

        const queryString = new URLSearchParams( queryParamsWithUtm ).toString();

        if ( queryString ) {
            return `${ baseUrl }?${ queryString }`;
        }

        const storedParams = JSON.parse( localStorage.getItem( 'test_query_params' ) ) || {};
        const storedQueryString = new URLSearchParams( storedParams ).toString();

        return `${ baseUrl }${ storedQueryString ? `?${ storedQueryString }` : '' }`;
    };

    useEffect( () => {
        if ( test_series_id ) {
            navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/email_verification/` ), { replace: true, state: { test_series_id: testSeriesID } } );
        }
    }, [test_series_id] )

    useEffect( () => {
        if ( !flowBlocks ) return;

        switch ( currentFlowName ) {
            case "questionnaire_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testID }` ), { replace: true } );
                break;
            case "email_verification":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testID }/email_verification` ), { replace: true } );
                break;
            case "phone_verification":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testID }/phone_verification` ), { replace: true } );
                break;
            case "sign_up_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/signup` ), { replace: true } );
                break;
            case "coupon_code_verification":
                navigate( constructUrlWithParams( `/${ client_name }/verification/coupon_code` ), { replace: true } );
                break;
            case "instruction_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testID }/instructions` ), { replace: true, state: { test_series_id: testSeriesID } } );
                break;
            case "test_selection_view":
                navigate( constructUrlWithParams( `/${ client_name }/test/selection/` ), { replace: true } );
                break;
            case "thank_you_page_view":
                if ( template_id ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/` ), { replace: true } );
                } else if ( userNameLocalStorage && userEmailLocalStorage ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/?completed=true` ), { replace: true } );
                } else {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/?completed=true` ), { replace: true } );
                }
                break;
            case "application_form_thank_you_page_view":
                if ( template_id ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/` ), { replace: true } );
                } else if ( userNameLocalStorage && userEmailLocalStorage ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/?completed=true` ), { replace: true } );
                } else {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/?completed=true` ), { replace: true } );
                }
                break;
            default:
                break;
        }
    }, [flowBlocks, currentFlowName] );

    return <div>
        { isLoading &&
            <div className="flex items-center justify-center h-screen" >
                <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
            </div>
        }
    </div>;
};

export default FlowBlocksChange;